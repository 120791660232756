import "./ozone.css";
import OzoneBox from "./OzoneBox";
import aroma from "../../images/aroma.png";
import bug from "../../images/bug.png";
import mold from "../../images/mold.png";
import sneeze from "../../images/sneeze.png";
import virus from "../../images/virus.png";
import Button from "./../button/Button";

export default function Ozone() {
    return (
        <main className="ozone" id="ozone">
            <div className="ozoneWrapper">
                <h2 className="ozoneTitle">
                    <span>
                        Sokoldalú fertőtlenítés
                    </span>
                    Ózonos tisztítással kellemesebb és egészségesebb légkört biztosítunk!
                </h2>
                <h3 className="ozoneSubTitle">
                    Az ózongenerátorral végzett fertőtlenítést ezekben az esetekben javasoljuk:
                </h3>
                <div className="ozoneBoxes">
                    <OzoneBox 
                        src={aroma}
                        alt="alt-name"
                        title="Rossz szagok"
                        intro="Otthona, munkahelye, tárgyai, iratai rossz szagot árasztanak?
                        Esetleg doh vagy füstszag terjgeng az egész helyiségben? "
                        desc="Ebben az esetben olyan technológiát szükséges alkalmazni, ami nem csak elfedi a kellemetlen szagokat. A napjainkban divatos automata légfrissítők belélegzésével sajnos sokat ártunk egészségünknek, és nem kezeli a probléma forrását. Az ózonos tisztítással megsemmisítjük a szagmolekulákat, ezáltal megszűnik a kellemetlen szag oka. A légtér sokkal frissebb és egészségesebb lesz, ahogyan Ön is!"
                    />
                    <OzoneBox 
                        src={mold}
                        alt="alt-name"
                        title="Penészgombák"
                        intro="Ártalmatlannak tűnik, de nem az!
                        Vigyázzon és idejében lépjen fel a penésszel szemben!"
                        desc="A penészgomba spórája mindenhol jelen lehet a helyiségekben. Káros hosszú távon ezt a levegőt  beszívni. Sőt, folyamatos nedvesség hatására a penész tovább szaporodik mérgező anyagot bocsátva ezzel a levegőbe. Az ózonos tisztítással mind a gombát, mind a spórákat eltávolítjuk és megsemmisítjük. Bízza ránk ezt a feladatot, hogy újra egészségesen tiszta levegőt lélegezzen be!"
                    />
                    <OzoneBox 
                        src={virus}
                        alt="alt-name"
                        title="Vírusok"
                        intro="Hagyományos tisztítás vagy ózonos?
                        Miért nem elég, ha a hagyományos tisztítási módszereket használja?"
                        desc="A hagyományos tisztítási módszerek kevésbé hatékonyak kis réseken, lyukakon és területeken, ahová nem tudnak bejutni,  ezért nem megfelelő hatásfokkal vagy egyáltalán nem tisztítják ezeket. Gondolt arra, hogy a könyvek felületén több ezer vírus és mikroszkopikus organizmus található? Megdöbbentő, de igaz. Sőt, amikor ezzel a felülettel valaki bármilyen módon érintkezik a szervezetek ragaszkodnak hozzájuk, majd táplálkoznak belőlük. Az ózonos tisztítás olyan mértékben károsítja a vírusokat, hogy nem képesek elterjedni, és rövid idő alatt elpusztulnak. Ez a módszer tökéletes a dokumentumok és könyvek fertőtlenítésére a papír, vagy a felület károsítása nélkül."
                    />
                    <OzoneBox 
                        src={bug}
                        alt="alt-name"
                        title="Atkák"
                        intro="Amilyen apró, olyan veszélyes!
                        Attól, hogy nem látja, még ott vannak szőnyegeiben, matracaiban, bútoraiban."
                        desc="Az ágyi poloskák általában kisebbek, mint 1-mm, ami azt jelenti, hogy emberi szemmel jellemzően nem láthatóak. Sajnos ez a probléma nagyon sok ember életének része. Az ágyi poloskák a matracban, szőnyegben vagy más bútorokban bújnak el. Az ózonos tisztítással elpusztítjuk az atkákat, poloskákat, még a hagyományos tisztítószerek által megközelíthetetlen területeken is."
                    />
                    <OzoneBox 
                        src={sneeze}
                        alt="alt-name"
                        title="Allergének"
                        intro="Korunk legnagyobb ellenségei.
                        Ön is küzd allergiás problémákkal?"
                        desc="Nagyrészt a kémiai maradványokból erednek az allergiás reakciók, de az ételek, vagy a kozmetikumok is allergiát válthatnak ki. Az otthonainkban található bútorokon és tárgyakban valószínűleg virágpor, por vagy valamilyen kémiai anyag található. Ezek az anyagok irritációt okozhatnak egyes embereknek. Az ózonos tisztítással az allergiás reakciók sokkal kisebb eséllyel jelennek meg."
                    />
                </div>
                <div className="ozoneContact">
                    <span class="ozoneContactTitle">Kérem az ózonos tisztítást</span>
                    <Button 
                        link="#contact"
                        text="Kapcsolat"
                    />
                </div>
            </div>
        </main>
    )
}
