import LogoColor from "../../images/logo-color.png";
import Button from "./../button/Button";

export default function ServiceBox(props) {
    return (
        <div className="serviceBox">
            <img
                className="serviceImg" 
                src={props.src} 
                alt="ozone for you"
            />
            <div className="serviceBoxHeader">
                <h3 className="serviceTitle">
                    {props.title}
                </h3>
                <p className="servicePrice">
                    {props.price}
                </p>
            </div>
            <p className="serviceDesc">
                {props.desc}
            </p>
            <p className="serviceDesc">
                {props.descTwo}
            </p>
            <div className="serviceBoxFooter">
                <img 
                    className="serviceLogo"
                    src={LogoColor} 
                    alt="logó" 
                />
                <Button 
                    link="#contact"
                    text="Érdekel"
                />
            </div>
        </div>
    )
}
