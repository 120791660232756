import "./header.css";
import Logo from "../../images/logo.png";
import LogoO3White from "../../images/logo-o3-white.png";
import { useState } from "react";

export default function Header() {

    const [isOpenNav, setIsOpenNav] = useState(false);

    return (
        <header className="header">

            <div className="navWrapper"
                 style={isOpenNav ? { transform: "translateY(0)" } 
                                  : { transform: "translateY(-100%)" }}
            >
                <div className="navHeader">
                    <div className="headerSidebar">
                        <div className="headerSocial navSocial">
                            <a className="link" 
                               href="https://www.facebook.com/ozoneforyouhu" 
                               target="_blank" 
                               rel="noreferrer"
                            >
                                <i className="headerSocialIcon fab fa-facebook-f"></i>
                            </a>
                            <a className="link" 
                               href="https://www.instagram.com/ozoneforyou_hu/" 
                               target="_blank" 
                               rel="noreferrer"
                            >
                                <i className="headerSocialIcon fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="headerCenterbar">
                        <img className="headerLogo" 
                            src={Logo} 
                            alt="Ozone logó" 
                        />
                    </div>
                    <div className="headerSidebar headerRightbar navClose">
                        Bezár
                        <i className="navCloseBtn fas fa-times"
                           onClick={() => setIsOpenNav(false)}
                        ></i>
                    </div>
                </div>
                <div className="navLogo">
                    <img 
                        className="navLogoImg"
                        src={LogoO3White} 
                        alt="Ozone logó" 
                    />
                </div>
                <nav className="nav">
                    <ul className="navList">
                        <li className="navItem"
                            onClick={() => setIsOpenNav(false)}
                        >
                            <a className="link" href="#about">Hogy működik?</a>
                        </li>
                        <li className="navItem"
                            onClick={() => setIsOpenNav(false)}
                        >
                            <a className="link" href="#ozone">Alkalmazás</a>
                        </li>
                        <li className="navItem"
                            onClick={() => setIsOpenNav(false)}
                        >
                            <a className="link" href="#services">Hol használjuk</a>
                        </li>
                        <li className="navItem"
                            onClick={() => setIsOpenNav(false)}
                        >
                            <a className="link" href="#contact">Kapcsolat</a>
                        </li>
                    </ul>
                </nav>
                <div className="navFooter">
                    <span className="navFooterBox navFooterTitle">Elérhetőségek</span>
                    <span className="navFooterBox">hello@ozoneforyou.hu</span>
                    <span className="navFooterBox">+3630/379-1322</span>
                </div>
            </div>
            
            <div className="headerWrapper">
                <div className="headerSidebar">
                    <div className="headerSocial">
                        <a className="link" 
                        href="https://www.facebook.com/ozoneforyouhu" 
                        target="_blank" 
                        rel="noreferrer"
                        >
                            <i className="headerSocialIcon fab fa-facebook-f"></i>
                        </a>
                        <a className="link" 
                               href="https://www.instagram.com/ozoneforyou_hu/" 
                               target="_blank" 
                               rel="noreferrer"
                            >
                                <i className="headerSocialIcon fab fa-instagram"></i>
                            </a>
                    </div>
                </div>
                <div className="headerCenterbar">
                    <img className="headerLogo" 
                        src={Logo} 
                        alt="logó" 
                    />
                </div>
                <div className="headerSidebar headerRightbar">
                    Menü
                    <div className="navOpenBtn"
                        onClick={() => setIsOpenNav(true)}
                    >
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </header>
    )
}
