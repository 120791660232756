import "./contact.css";

export default function Contact() {
    return (
        <section className="contact" id="contact">
            <div className="contactWrapper">
                <div className="contactBox">
                    <h3 className="contactTitle">
                        Kapcsolat
                    </h3>
                    <div className="contactDesc">
                        <p>
                            Kérdése van? <br />
                            Vegye fel velünk a kapcsolatot! <br />
                            Készséggel segítünk önnek!
                        </p>
                        <p>
                            +36 30 379 1322
                        </p>
                        <p>
                            hello@ozoneforyou.hu
                        </p>
                    </div>
                    <div className="contactSocial">
                        <a className="link" 
                            href="https://www.facebook.com/ozoneforyouhu" 
                            target="_blank" 
                            rel="noreferrer"
                        >
                            <i className="headerSocialIcon fab fa-facebook-f"></i>
                        </a>
                        <a className="link" 
                            href="https://www.instagram.com/ozoneforyou_hu/" 
                            target="_blank" 
                            rel="noreferrer"
                        >
                            <i className="headerSocialIcon fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="contactBox">
                    <form 
                        className="contactForm" 
                        action="https://formsubmit.co/hello@ozoneforyou.hu" 
                        method="POST"
                    >
                        <input 
                            className="contactInput" 
                            type="text" 
                            name="name" 
                            placeholder="Név" 
                            required
                        />
                        <input 
                            className="contactInput" 
                            type="email" 
                            name="email" 
                            placeholder="Email" 
                            required
                        />
                        <input 
                            className="contactInput" 
                            type="text" 
                            name="phone" 
                            placeholder="Telefon" 
                            required
                        />
                        <input 
                            type="hidden" 
                            name="_template" 
                            value="table"
                        />
                        <input 
                            type="hidden" 
                            name="_next" 
                            value="https://www.ozoneforyou.hu/"
                        />
                        <textarea 
                            className="contactInput contactMessageDesc" 
                            name="message" 
                            type="text" 
                            placeholder="Üzenet" 
                            required
                        >
                        </textarea>
                        <button 
                            className="contactFormSubmit" 
                            type="submit"
                        >
                            Küldés
                        </button>
                    </form>
                </div>
            </div>
        </section>
    )
}
