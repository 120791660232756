import "./ozone.css"; 

export default function OzoneBox(props) {
    return (
        <div className="ozoneBox">
            <img src={ props.src } alt={ props.alt } />
            <h4 className="ozoneBoxTitle">{ props.title }</h4>
            <p className="ozoneBoxIntro">{ props.intro }</p>
            <p className="ozoneBoxDesc">{ props.desc }</p>
        </div>
    )
}
