import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Header from "./components/header/Header";
import Intro from "./components/intro/Intro";
import Ozone from "./components/ozone/Ozone";
import ScrollUp from "./components/scrollUp/ScrollUp";
import Services from "./components/services/Services";
import Questions from "./questions/Questions";

function App() {

  return (
    <div className="App">
        <Header />
        <Intro /> 
        <About />
        <Ozone />
        <Questions />
        <Services />
        <Contact />
        <ScrollUp />
    </div>
  );
}

export default App;