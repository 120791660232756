import "./questions.css";

export default function Questions() {
    return (
        <section className="questions">
            <h3 className="questionsTitle">Kérdések és válaszok</h3>
            <span className="questionsIntro">
                Összegyűjtöttünk néhány kérdést és választ, amely segít eligazodni az ózonos tisztítás folyamatában.
            </span>
            <div className="questionBox">
                <h4 className="questionSubtitle">
                    Hol lehet használni az ózonos tisztítást?
                </h4>
                <p className="questionDesc">
                    Minden olyan területen alkalmazható az ózongenerátoros fertőtlenítés, amelyet akár csak átmenetileg is, de le lehet zárni. Ez azért szükséges, hogy a helyiség megtölthető legyen a generátorok által előállított ózonnal. Ez a kulcsa annak, hogy hatékonyan fertőtleníthető legyen a terület.
                </p>
            </div>
            <div className="questionBox">
                <h4 className="questionSubtitle">
                    Mennyi időt vesz igénybe az ózongenerátoros fertőtlenítés?
                </h4>
                <p className="questionDesc">
                    Mivel több ózongenerátorral dolgozunk, így jelentősen lerövidíthető a fertőtlenítés időtartama, aminek köszönhetően akár 1 óra alatt is fertőtleníthető egy-egy terület. Ez természetesen függ a terület méretétől és a szellőztetési lehetőségektől is.
                </p>
            </div>
            <div className="questionBox">
                <h4 className="questionSubtitle">
                    Mire kell figyelni a fertőtlenítést követően?
                </h4>
                <p className="questionDesc">
                    Az ózonos tisztítás végeztével szellőztetés szükséges, melyet szükség esetén ventilátorokkal támogatunk, hogy minél gyorsabban újra használatba lehessen venni az területet. Igény esetén éjszakai fertőtlenítés is megoldható, amennyiben a fertőtlenítendő terület jellege ezt megköveteli.
                </p>
            </div>
            <div className="questionBox">
                <h4 className="questionSubtitle">
                    Miért ilyen hatékony ez az eljárás?
                </h4>
                <p className="questionDesc">
                    Az ózon az egyik legerősebb oxidálószer, amely hatékonyan semlegesíti a levegőben és vízben lévő vírusokat, penészt, gombákat, baktériumokat, szagmolekulákat. 
                    Az ózongáz (O3) három oxigénatomot tartalmaz, míg a belélegzett levegő (O2) kettőt. A harmadik oxigénmolekula rendkívül reakcióképessé teszi az ózont, amely így könnyen tapad más molekulákhoz. Az így megtapadó ózon kárt tesz az élő szövetekben, a mikroorganizmusok sejthártyáját megbontja, ezáltal végez velük.
                </p>
            </div>
        </section>
    )
}
