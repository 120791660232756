import "./scrollUp.css";

export default function ScrollUp() {

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    
    return (
        <div className="scrollUp" onClick={scrollUp} >
            <i className="scrollUpIcon fas fa-chevron-up"></i>
        </div>
    )
}
