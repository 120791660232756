import "./button.css";

export default function Button(props) {
    return (
        <a className="button link" href={props.link}>
            <span>{props.text}</span>
            <span>{props.text}</span>
        </a>
    )
}
